import { Injectable } from '@angular/core';
import { CountryConfigRestService } from '../../rest-services/country-config-rest.service';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TicketStatusForCustomer } from '../../models/tickets/ticket-status';
import { TicketsRestService } from '../../rest-services/tickets-rest.service';
import { TicketsWebsocketStateService } from './tickets-websocket-state.service';
import { MyFilterWebsocketService } from '../my-filter-websocket/my-filter-websocket.service';
import { Customer } from '../../models/customer/customer';

@Injectable({providedIn: 'root'})
export class TicketsWebsocketService {

  ticketWebserviceMessage$ = new BehaviorSubject<TicketStatusForCustomer>({
    customerId: ''
  });

  ticketStatusCacheTimeoutInSeconds: number;

  ticketStatusRefreshTimestamp: Date = new Date();

  constructor(
    private countryConfig: CountryConfigRestService,
    private ticketsRestService: TicketsRestService,
    private ticketsWebsocketStateService: TicketsWebsocketStateService,
    private myFilterWebsocketService: MyFilterWebsocketService) {
  }

  startWatchersForCustomerIds(customerList: Customer[]): Observable<TicketStatusForCustomer> {
    return this.ticketsRestService.getTicketsStatusFromWebsocketMessages(
      of(customerList.map(customer => customer.customerId)));
  }

  disableRefreshAllTicketButton(): Observable<boolean> {
    return this.ticketWebserviceMessage$.pipe(map(ticketStatus => this.evaluateDisableRefreshTicketButton(ticketStatus)));
  }

  private evaluateDisableRefreshTicketButton(ticketStatus: TicketStatusForCustomer): boolean {
    if (this.isEquipmentWithCustomerIdImpersonated(ticketStatus?.customerId)) {
      this.ticketsWebsocketStateService.refreshButtonDisabledState = false;
    }

    return this.ticketsWebsocketStateService.refreshButtonDisabledState;
  }

  private isEquipmentWithCustomerIdImpersonated(newCustomerId: string) {
    return this.myFilterWebsocketService.myShowingEquipmentCustomersList$.value.find(customer => customer.customerId === newCustomerId);
  }

  reloadTicketsStatus(customerStatus: TicketStatusForCustomer) {
    this.ticketWebserviceMessage$.next(customerStatus);
  }

  activateWebsocketTicketsStatus() {
    this.countryConfig.getConfig().pipe(
      switchMap(config => {
        this.ticketStatusCacheTimeoutInSeconds = parseInt(config.TICKET_STATUS_CACHE_TIMEOUT_SECONDS, 10);

        // When new value comes, it will cancel previous watchers created in startWatchersForCustomerIds,
        // this is crucial part for correct functioning with my equipment switch,
        // so be aware before any change
        return this.myFilterWebsocketService.myShowingEquipmentCustomersList$;
      }),
      switchMap((customerList) =>
        this.startWatchersForCustomerIds(customerList))
    ).subscribe((value) => {
      if (value) {
        this.reloadTicketsStatus(value);
      }
    });
  }

  setRefreshButtonDisability(disable: boolean) {
    this.ticketsWebsocketStateService.refreshButtonDisabledState = disable;
  }

  refreshDataOnTabSwitch() {
    this.clearCacheWhenNewDataArePresent();
  }

  private clearCacheWhenNewDataArePresent() {
    if (!this.ticketsWebsocketStateService.refreshButtonDisabledState) {
      this.ticketsWebsocketStateService.manageCacheForWebsocketTickets();
      this.reloadTicketsStatus({
        customerId: ''
      });

    } else if (this.timeoutForTicketStatusCachedPassed()) {
      this.callEvaluationForTickets();
      this.ticketStatusRefreshTimestamp = new Date();
    }
  }

  private callEvaluationForTickets() {
    if (this.myFilterWebsocketService.myShowingEquipmentCustomersList$.value.length) {
      this.ticketsRestService.refreshTickets({
        customerIds: this.myFilterWebsocketService.myShowingEquipmentCustomersList$.value.map(customer => customer.customerId)
      });
    }
  }

  private timeoutForTicketStatusCachedPassed() {
    return !!this.ticketStatusCacheTimeoutInSeconds &&
      (new Date().getTime() - this.ticketStatusRefreshTimestamp.getTime() >
        this.ticketStatusCacheTimeoutInSeconds * 1000);
  }
}
